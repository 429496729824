import React from 'react'
import { CFooter } from '@coreui/react'

const AppFooter = () => {
	return (
		<CFooter className="px-4">
			<p>© {new Date().getFullYear()} Maxtron Show Lighting. All Rights Reserved.</p>
		</CFooter>
	)
}

export default React.memo(AppFooter)