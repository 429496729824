import React, { useState, useEffect } from 'react';
import Navbar from 'components/Navbar';
import Header from 'components/Header';
import Footer from 'components/Footer';
import ProductCard from 'components/ProductCard';
import SubscribeButton  from 'components/SubscribeButton';
// import HeaderImage from 'assets/images/background-image/about-bg.webp';

function NewsList() {
	const [featuredNews, setFeaturedNews] = useState([]);
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState('');

	const truncateDescription = (description, wordLimit = 15) => {
		if (!description)
			return null;
		const words = description.split(' ');
		if (words.length <= wordLimit)
			return description;
		return words.slice(0, wordLimit).join(' ') + '...';
	};

	useEffect(() => {
		window.scrollTo(0, 0);
		const fetchFeaturedNews = async () => {
			try {
				const response = await fetch(`${process.env.REACT_APP_API_URL}guest/news`);
				const data = await response.json();
				setFeaturedNews(data.data);
			} catch (error) {
				setError('Failed to load news. Please try again later.');
				console.error('Featured news not found:', error);
			} finally {
				setLoading(false);
			}
		};

		fetchFeaturedNews();
	}, []);

	return (
		<>
			<Navbar />
			<Header page='news_page' />
			<div className='container-fluid bg-black'>
				<div className='container'>
					<div className='py-4'>
						<nav aria-label="breadcrumb">
							<ol className="guest-breadcrumb breadcrumb">
								 <li className="breadcrumb-item"><a href="/">Home</a></li>
								 <li className="breadcrumb-item"><a href="news">News</a></li>
							</ol>
						</nav>
					</div>
					<h1 className='text-white py-4'>News</h1>
					{loading ? (
						<p>Loading...</p>
					) : error ? (
						<p>{error}</p>
					) : (
						<div className="row pt-2">
							{featuredNews.length > 0 ? (
								featuredNews.map((news, index) => (
									<div className="col-md-3 mb-4" key={index}>
										<ProductCard
											id={news._id}
											image={`${process.env.REACT_APP_BACKEND_URL}${news.imagePath[0]}`}
											name={news.title}
											description={truncateDescription(news.description)}
											date={news.createdAt}
											render={`/news/detail/${news._id}`}
										/>
									</div>
								))
							) : (
								<p>No news available.</p>
							)}
						</div>
					)}
				</div>
			</div>
			<Footer />
			<SubscribeButton />
		</>
	);
}
	
export default NewsList;